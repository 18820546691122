@font-face {
  font-family: 'Avenir';
  src: url('/public/fonts/AvenirNextCondensed-Regular.otf') format('opentype'), 
      url('/public/fonts/AvenirNextCondensed-Medium.otf') format('opentype'),
      url('/public/fonts/AvenirNextCondensed-Bold.otf') format('opentype'),  
}
html, body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  background: transparent;
}
body::-webkit-scrollbar {
  display: none;
}
#root {
  position: relative;
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  background: rgb(253,248,255);
}
#root::-webkit-scrollbar {
  display: none;
}
#root h1 {
  background: rgb(20, 181, 163);
  color: white;
  margin: 0;
  padding: 0;
  text-align: center;
  font-family: 'Avenir';
  font-weight: bold;
  font-size: clamp(1em, 4em, 35px);
  padding: 10px;
  max-width: 100vw;
}

.navbar {
  position: absolute;
  background: transparent;
  height: 12vh;
  max-height: 90px;
  min-height: 70px;
  top: calc(10% + 0.5vh);
  left: 0;
  width: 100%;
  margin: 0;
  padding: 0;
  z-index: 50;
  display: flex;
  align-items: center;
  justify-content: center;
}
.nav-menu {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5%;
}
.nav-menu img {
  height: 1.4em !important;
  width: 1.4em !important;
}
.desktop-nav {
  position: absolute;
  top: 12vh;
  right: 0;
  left: auto;
  width: 100%;
  font-size: 1em;
  background: rgba(20, 181, 163, 0.8);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 2vh;
}
.desktop-nav-flex {
  height: 100%;
  width: 70%;
  margin-left: 20%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
}
@media screen and (min-width: 900px) {
  .navbar {
    height: 12vh;
    max-height: 90px;
    background: rgba(20, 181, 163, 0.8);
    top: 0;
    right: 0;
    left: auto;
    width: 20%;
  }
  .desktop-nav {
    top: min(12vh, calc(90px));
  }
}
@media screen and (min-width: 1200px) {
  .navbar {
    height: 110px;
    background: rgba(20, 181, 163, 0.8);
    top: 0;
    right: 0;
    left: auto;
    width: 18%;
  }
  .desktop-nav {
    top: 90px;
  }
}
.desktop-nav p {
  padding: 0;
  margin: 0;
  font-family: 'Avenir';
  font-size: 1.3em;
  color: white;
  font-weight: normal;
  text-align: left;
  width: auto;
}
.desktop-nav-item {
  width: auto;
  padding: 0;
  margin-right: 5%;
  margin-bottom: 2vh;
}
.desktop-nav p:hover {
  color: #F27208ff;
  transform: scale(1.05) translateX(-10px);
}

/*mobile navbar*/
.navbar-button {
  border: none;
  background: transparent;
  margin: 0;
  margin-left: auto;
  margin-right: 5px;
  z-index: 12;
  opacity: 0.8;
  height: 10vh;
  max-height: 90px;
  width: 10vh;
  max-width: 80px;
  z-index: 50;
  padding: 0;
}
.navbar-button img {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  object-fit: fill !important;
}
.navbar-button-expanded {
  position: absolute;
  border: none;
  background: transparent;
  left: calc(100% - 50px);
  top: 15px;
  z-index: 12;
}
.nav-button {
  border: none;
  border-radius: 50%;
  background: rgb(253,248,255);

  position: fixed;
  top: 2vh;
  right: 2vh;
  height: 8vh;
  max-height: 70px;
  width: 8vh;
  max-width: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 8px -2px rgba(0, 0, 0, 0.4);
  z-index: 50;
}
.nav-button img {
  height: 60%;
  width: 80%;
  opacity: 0.8;
  z-index: 50;
}
.navbar-expanded {
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  width: 100vw;
  background: rgba(20, 181, 163, 1);
  color: white;
  font-family: 'Avenir';
  font-size: 1.6em;
  margin: 0;
  padding: 0;
  font-weight: bold;
  font-family: 'Avenir';
  z-index: 50;
}
.navbar-expanded-text {
  height: 100%;
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.navbar-flex {
  position: relative;
  height: 100%;
  width: 100%;
  background: rgb(20, 181, 163);
  display: flex;
  align-items: center;
  justify-content: center;
}
.navbar-expanded p {
  width: 100%;
  margin: 0;
  padding: 0;
}
.navbar-expanded div {
  margin-bottom: 1vh;
}
.mobile-locations p {
  font-size: 0.6em;
  font-weight: normal;
  text-align: center;
  display: flex;
  align-items: center;
}
.next-arrow {
  height: 1em !important;
  width: auto !important;
  margin-right: 5px;
}

/*images*/
.image-container {
  position: relative;
  width: 100%;
  height: 101vh;
  z-index: 0;
}
.images {
  height: 100%;
  width: 100%;
  object-fit: cover;
  position: absolute;
}
.image-title {
  position: absolute;
  top: 10%;
  left: 0;
  width: 60vw;
  max-width: 400px;
  height: 12vh;
  min-height: 70px;
  max-height: 90px;
  background: rgba(20, 181, 163, 0.8);
  display: flex;
  align-items: center;
  z-index: 12;
}
.title-logo {
  position: absolute;
  height: 135px !important;
  width: 180px !important;
  top: 10%;
  transform: translateY(-25%);
  left: -1%;
  z-index: 12;
}
.image-title p  {
  width: 70%;
  margin-left: 30%;
  text-align: center;
  font-family: 'Avenir';
  font-size: 2em;
  font-weight: bolder;
  color: white;
}
@media screen and (min-width: 600px) {
  .title-logo {
    height: 190px;
    width: 240px;
  }
}
@media screen and (min-width: 800px) {
  .title-logo {
    height: 190px;
    width: 240px;
    top: 10%;
  }
  .image-title {
    max-width: 700px;
  }
}
@media screen and (min-width: 1200px) {
  .image-title {
    left: 0;
    left: auto;
    top: 18%;
    width: 80vw;
    max-width: 700px;
    height: 110px;
  }
  .title-logo {
    top: 18%;
    transform: translateY(-30%);
    left: -1%;
    height: 190px;
    width: 240px;
  }
}


.ausgangslage {
  margin: 0;
  padding: 0;
  width: 100vw;
  background: rgb(253,248,255);
  overflow: hidden;
  position: relative;
}
.ausgangslage-div {
    position: absolute;
    bottom: -14vh;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    z-index: 20;
}
.ausgangslage-div svg {
  position: relative;
  display: block;
  width: calc(129% + 1.3px);
  height: 20vh;
}
.shape-fill {
  fill: rgba(20, 181, 163, 1);
}
.ausgangslage-grid {
  display: grid;
  grid-template-columns: 40% 60%;
}
.ausgangslage-grid-left {
  display: grid;
  grid-template-columns: 60% 40%;
}
.ausgangslage p {
  width: 90%;
  max-width: 500px;
  padding-top: 20px;
  text-align: justify;
  font-family: 'Avenir';
  font-size: 1.2rem;
  margin: auto;
  hyphens: auto;
}
.quote {
  color: #F27208ff;
  font-size: 25px !important;
  font-weight: bold;
  text-align: center !important;
  font-family: Avenir;
  width: 90vw;
  max-width: 500px;
}
.grid-image {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  height: auto;
}
.grid-image img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.grid-text {
  height: auto;
  width: 100%;
}
@media screen and (min-width: 1000px) {
  .grid-image {
    height: 70vh;
    max-height: 600px;
    object-fit: cover;
  }
  .grid-text {
    padding: 20px;
    padding-left: 0;
    height: 70vh;
    max-height: 600px;
  }
  .ausgangslage p {
    padding-top: 0;
    margin: auto;
  }
}

.logo-holder {
  padding-top: 20px;
  height: auto;
}
.logo-container {
  width: 320px;
  height: 400px;
  position: relative;
  margin: auto;
}
.logo-part {
  position: absolute;
  top: 18%;
  left: 50%;
  transform: translateX(-50%);
  height: 30%;
  width: 48%;
}
.coach-part {
  position: absolute;
  left: 1%;
  height: 50%;
  width: 48.5%;
}
.box-part {
  position: absolute;
  right: 1%;
  height: 50%;
  width: 48.5%;
}
.block-part {
  position: absolute;
  top: 43.7%;
  left: 14%;
  height: 32%;
  width: 71.5%;
}
.block-part:active {
  transform: scale(1.2);
}
.box-part:active {
  transform: scale(1.2);
}
.coach-part:active{
  transform: scale(1.2);
}
@media screen and (min-width: 1200px) {
  .block-part:hover {
    transform: scale(1.2);
  }
  .box-part:hover {
    transform: scale(1.2);
  }
  .coach-part:hover{
    transform: scale(1.2);
  }
}
.logo-text {
  width: 90%;
  max-width: 500px;
  margin: auto;
  text-align: justify;
  height: 100%;
  padding-top: 20px;
}
.logo-text-text {
  display: inline;
  font-family: 'Avenir';
  font-size: 1.2em;
  width: 100%;
}
#logo-title-one {
  color: rgb(20, 181, 163);
  font-weight: bold;

  background: linear-gradient(to left, transparent 50%, rgba(242, 114, 8, 0.8) 50%) right;
  background-size: 201%;
  transition: 0.5s ease-out;
  width: 100%;
  padding: 0;
}
#logo-title-one.active-logo {
  background-position: left;
}

#logo-title-two {
  color: rgb(20, 181, 163);
  font-weight: bold;

  background: linear-gradient(to left, transparent 50%, rgba(242, 114, 8, 0.8) 50%) right;
  background-size: 201%;
  transition: 0.5s ease-out;
  width: 100%;
  padding: 0;
  margin-top: 20px;
}
#logo-title-two.active-logo {
  background-position: left;
}

#logo-title-three {
  color: rgb(20, 181, 163);
  font-weight: bold;

  background: linear-gradient(to left, transparent 50%, rgba(242, 114, 8, 0.8) 50%) right;
  background-size: 201%;
  transition: 0.5s ease-out;
  width: 100%;
  padding: 0;
  margin-top: 20px;
}
#logo-title-three.active-logo {
  background-position: left;
}


.map-container {
  height: 60vh;
  min-height: 340px;
  width: 100%;
  max-width: 900px;
  margin: auto;
  background: rgb(253,248,255);
}
.first-marker {
  position: relative;
  height: 10vh;
  width: 8vh;
}
.second-marker {
  height: 10vh;
  width: 8vh;
}
.third-marker {
  height: 10vh;
  width: 8vh;
}
.first-image {
  height: 100%;
  width: 100%;
}
.map-title {
  background: rgb(20, 181, 163);
  color: white;
  margin: 0;
  padding: 0;
  text-align: center;
  font-family: 'Avenir';
  font-weight: bold;
  font-size: clamp(1em, 4em, 35px);
  padding: 10px;
}

/*text for the locations*/
.under-map {
  background: rgb(20, 181, 163);
  width: 100% !important;
  max-width: 900px !important;
  color: white;
  min-height: 40px;

  display: flex;
  text-align: center;
}
.under-map p {
  width: 90% !important;
  margin: auto !important;
  text-align: center !important;
  display: block !important;
  padding: 10px !important;
  padding-top: 20px;
  font-weight: bold;
}
.location-description {
  font-family: 'Avenir';
  font-size: 1.2em;
  width: 100%;
}
.location-description div {
  width: 100%;
  max-width: 500px;
  margin: auto;
}
.location-description h4 {
  display: inline;
  color: rgb(20, 181, 163);
  font-weight: bold;
  margin-left: 5px;
}
.location-description p {
  width: 90%;
  margin: auto;
  padding: 0;
  text-align: justify;
  display: inline;
}
#first-location {
  max-width: 500px;
  padding-top: 20px;
  padding-bottom: 20px;
  background: linear-gradient(to left, transparent 50%, rgba(242, 114, 8, 0.7) 50%) right;
  background-size: 201%;
  transition: 0.5s ease-out;
}
#first-location.active {
  background-position: left;
}
#second-location {
  max-width: 500px;
  padding-top: 20px;
  padding-bottom: 20px;
  background: linear-gradient(to left, transparent 50%, rgba(242, 114, 8, 0.7) 50%) right;
  background-size: 201%;
  transition: 0.5s ease-out;
}
#second-location.active {
  background-position: left;
}
@media  screen and (min-width: 1200px) {
  #first-location {
    max-width: 700px;
  }
  #second-location {
    max-width: 700px;
  }
}


/*for the non-locations*/
.no-location {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background: rgba(230,230,230,0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 200;
}
.no-location div {
  height: auto;
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: 'Avenir';
  font-size: 1.2em;
  z-index: 100;
}
.location-closer {
  position: absolute;
  top: 1%;
  right: 1%;
  z-index: 5;

  border: none;
  background: transparent;
  height: 30px;
  width: 40px;
}
.no-location p {
  width: 80%;
  max-width: 500px;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 0;
}
.location-contact {
  position: relative;
  margin-top: 20px;
  font-family: 'Avenir';
  font-size: 1em;
  padding: 10px;
  background: rgba(242, 114, 8, 1);
  box-shadow: 0 2px 10px -3px rgba(0, 0, 0, 0.4);
  border: none;
  border-radius: 25px;
}
.location-contact:hover {
  transform: translateY(1px);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.4);
}
.location-contact:active {
  transform: translateY(1px);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.4);
}
/*Mehr über die zukünftigen Standorte, und der Aufbauplan als interaktive Liste*/
.future {
  width: 100vw;
}
.future-grid {
  display: block;
  padding: 0;
  padding-bottom: 20px;
}
.future-image {
  position: relative;
  bottom: 0;
  left: 0;
  height: 240px;
  width: 100%;
  max-width: 500px;
  margin-top: 20px;
  object-fit: cover;
}
.future-left {
  padding-top: 20px;
  position: relative;
  height: 100%;
  width: 90%;
  max-width: 500px;
  margin: auto;
  text-align: justify;
  font-family: 'Avenir';
  font-size: 1.2em;
}
.future-right {
  position: relative;
  height: 100%;
  width: 90vw;
  max-width: 500px;
  margin: auto;
  text-align: justify;
  font-family: 'Avenir';
  font-size: 1.2em;
  padding: 0;
}
.future-left p {
  margin: 0;
}
.future h2 {
  color: rgb(20, 181, 163);
  width: 90%;
  max-width: 500px;
  font-size: 2rem;
}
.future-title {
  color: rgb(20, 181, 163);
  font-weight: 700;
  display: inline;
}
.future-inline {
  display: inline;
}
.mission {
  background: rgb(20, 181, 163);
  color: white;
  list-style: square;
  padding: 0;
  padding-top: 20px;
  padding-bottom: 20px;
  margin: 0;
  font-size: 1em;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.mission li {
  width: 80%;
  font-family: 'Avenir';
  margin: 0;
  margin-top: 20px;
  padding: 0;
}
@media screen and (min-width: 1000px) {
  .mission {
    font-size: 0.8em;
  }
}
@media screen and (min-width: 1000px) {
  .future-grid {
    position: relative;
    display: grid;
    grid-template-columns: 50% 50%;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .future-image {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 350px;
    width: 100%;
    max-width: 500px;
    margin-top: 0;
  }
  .future-left {
    padding-top: 0;
  }
  .mission {
    max-width: 400px;
    margin-left: auto;
    margin-right: 0;
  }
}

.partners {
  padding-top: 20px;
}
.partners p {
  font-family: 'Avenir';
  font-size: 1.2em;
  width: 100vw;
  max-width: 700px;
  margin: 0;
  margin: auto;
  text-align: center;
}
.partners-list {
  font-family: 'Avenir';
  list-style-type: none;
  width: 100vw;
  max-width: 700px;
  margin: 0;
  margin: auto;
  margin-top: 50px;
  margin-bottom: 50px;
  padding: 0;
}
.partners-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 40px;
  margin-bottom: 40px;
  position: relative;
}
.partners-item p {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  text-align: end;
  font-size: 1.5em;
}
.partners-item img {
  height: 5em;
  object-fit: contain;
}
.partners button {
  border: none;
  border-radius: 25px;
  margin: auto;
  margin-top: 20px;
  padding: 10px;
  margin-left: 50%;
  transform: translateX(-50%);
  width: max-content;
  font-family: 'Avenir';
  font-size: 1.2em;
  background: rgb(20, 181, 163);
  color: white;
  box-shadow: 0 2px 10px -3px rgba(0, 0, 0, 0.4);
}
.partners button:hover {
  transform: translateX(-50%) translateY(1px);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.4);
}

.partners button:active {
  transform: translateX(-50%) translateY(2px);
  box-shadow: none;
}

@media screen and (max-width: 1000px) {
  .partners-item {
    justify-content: center;
  }
  .partners-item img {
    height: 5em;
    max-width: 80vw;
  }
}
/*Footer Part*/
.footer-image {
  position: relative;
  height: 60vh;
  width: 100%;
  margin-top: 20px;
}
.footer-images {
  height: 60vh;
  width: 100%;
  object-fit: cover;
  object-position: center;
}
.footer-title {
  position: absolute;
  top: -40%;
  left: 0;
  width: 60vw;
  height: 12vh;
  max-height: 100px;
  min-height: 70px;
  opacity: 0.9;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.9em;
}
.footer-title p  {
  position: absolute;
  width: 90vw;
  text-align: center;
  font-family: 'Avenir';
  font-size: 1.5em;
  font-weight: bolder;
  color: white;
  top: 250%;
  left: 50vw;
  transform: translateX(-50%);
}
@media  screen and (min-width: 900px) {
  .footer-image {
    height: 80vh;
  }
  .footer-images {
    height: 80vh;
  }
  .footer-title {
    position: absolute;
    top: 10%;
    transform: translateY(-50%);
    left: 50%;
    transform: translateX(-50%);
    width: 60vw;
    height: 12vh;
    min-height: 70px;
    background: rgb(20, 181, 163);
    opacity: 0.9;
    display: flex;
    align-items: center;
  }
  .footer-title p {
    position: relative;
    top: 0;
    left: 50%;
    width: 100%;
    margin-left: 0;
    text-align: center;
  }
}
.contacts {
  width: 100vw;
  height: 110vh;
  max-height: 120vh;
  background: rgb(20, 181, 163);
  padding-top: 50px;
  position: relative;
}
.email {
  height: 35vh;
  width: 80vw;
  max-width: 400px;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.email img {
  height: 200px;
  width: 255px;
}
.email p {
  font-family: 'Avenir';
  font-size: 2em;
  font-weight: 900;
  margin: 0;
}
.email-input {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2vh;
  height: auto;
  width: 90%;
  max-width: 500px;
  margin: auto;
  margin-top: 20px;
  font-family: 'Avenir';
  font-size: 1.2em;
  padding-bottom: 20px;
}
.write {
  width: 80%;
  height: 30px;
  border-radius: 25px;
  border: none;
  font-family: 'Avenir';
  font-size: 1em;
  padding-left: 10px;
}
.text {
  width: 80%;
  min-height: 200px;
  border-radius: 20px;
  border: none;
  font-family: 'Avenir';
  font-size: 1em;
  padding-left: 10px;
}
.mail-file-input {
  height: 4vh;
  width: 40%;
  background: rgba(0, 0, 0, 1);
  border-radius: 25px;
  color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.mail-file-input p {
  margin: 0;
  padding: 0;
  font-size: 0.8em;
}
.submit-button {
  width: 40%;
}
.submit-button button {
  margin: auto;
  padding-top: 5px;
  padding-bottom: 5px;
  width: 100%;
  border: none;
  border-radius: 25px;
  background: #F27208ff;
  font-size: 1.2em;
  font-family: 'Avenir';
  box-shadow:  0 2px 10px -3px rgba(0, 0, 0, 0.4);
}
.submit-button button:hover {
  transform: translateY(1px);
  box-shadow:  0 1px 2px rgba(0, 0, 0, 0.4);
}
.submit-button button:active {
  transform: translateY(1px);
  box-shadow:  0 1px 2px rgba(0, 0, 0, 0.4);
}
.end {
  height: auto;
  width: 100vw;
  background: rgb(20, 181, 163);
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
}
.socials {
  height: 8vh;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  gap: 10px;
  margin-left: 10px;
}
.social-icon {
  height: 6vh;
  width: 6vh;
}
.social-contact {
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 0;
}
.social-contact p {
  color: white;
  margin: 0;
  padding: 5px;
  font-size: 0.8em;
  font-family: Avenir;
  display: flex;
  align-items: center;
}

.fact-list {
  margin-top: 20px;
}
.fact-list ul {
  list-style: square;
  font-family: 'Avenir';
  font-size: 1.2em;
  max-width: 500px;
  margin: auto;
  margin-top: 20px;
}
.fact-list li {
  margin: 10px;
}


/* Das Team vorstellen */
.portrait {
  width: 100vw;
  overflow: hidden;
}
.portrait h4 {
  font-family: 'Avenir';
  font-weight: 700;
  text-align: center;
  font-size: 1.2em;
}
.image-box {
  box-sizing: border-box;
  min-height: 50vh;
  height: auto;
  width: 100vw;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding: 10px;
}
.image-box div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: visible;
}
.portrait-image {
  height: 250px;
  width: 250px;
  border-radius: 50%;
}
.portrait-image img {
  height: auto;
  width: auto;
  border-radius: 50%;
  object-fit: cover;
}
@media screen and (min-width: 1200px) {
  .image-box {
    justify-content: center;
    align-items: flex-start;
    flex-direction: row;
  }
  .portrait-image {
    height: 220px;
    width: 220px;
  }
}
.portrait-text {
  box-sizing: border-box;
  width: 100%;
  font-family: 'Avenir';
  font-size: 1.2em;
  margin: 0;
  margin-top: 20px;
  padding: 0;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 5px;
}
.portrait-text img {
  height: 1.2em;
}
.portrait-dropdown {
  width: 80vw;
  height: auto;
  margin-top: 20px;
  text-align: justify;
  font-family: 'Avenir';

  background: rgba(192,192,192, 0.6);
  color: black;
  z-index: 20;
}
.portrait-dropdown p {
  margin: 0;
  padding: 15px;
  font-size: 1em;
}
@media screen and (min-width: 1000px) {
  .portrait-dropdown {
    width: 220px;
  }
}




.present-data {
  background: rgb(20, 181, 163);
  padding-top: 5%;
  padding-bottom: 10%;
}
.present-data p {
  background: rgb(253,248,255);
  color: rgb(20, 181, 163);
  font-family: 'Avenir';
  font-size: 1.5em;
  font-weight: 900;
  width: 80%;
  margin-left: 40%;
  padding-left: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
}
@media  screen and (min-width: 1000px) {
  .present-data {
    position: relative;
    background: rgb(20, 181, 163);
    padding-top: 2%;
    padding-bottom: 10%;
    width: 100vw;
  }
  .present-data p {
    background: rgb(253,248,255);
    color: rgb(20, 181, 163);
    font-family: 'Avenir';
    font-size: 2em;
    font-weight: 900;
    max-width: 60vw !important;
    margin: 0;
    margin-left: auto;
    margin-right: 0;
    padding: 30px;
  }
}
.card-container {
  height: auto;
  min-height: 100vh;
}
.card {
  position: relative;
  height: 25vh;
  width: 80vw;
  border: 1px solid rgb(253,248,255);
  margin: auto;
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  font-family: 'Avenir';
}
.card p {
  background: transparent;
  color: white;
  font-family: 'Avenir';
  font-size: 1em;
  font-weight: normal;
  width: 80%;
  margin: 0;
  padding: 0;
}
.card-icon {
  position: absolute;
  top: -30px;
  left: -30px;
  height: 65px;
  width: 65px;
  background: rgb(253,248,255);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}
.card-icon img {
  height: 70%;
  width: 70%;
}
.card-icon-right {
  position: absolute;
  top: -30px;
  right: -30px;
  left: auto;
  height: 65px;
  width: 65px;
  background: rgb(253,248,255);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}
.card-icon-right img {
  height: 70%;
  width: 70%;
}
.data-grid {
  position: relative;
  display: grid;
  grid-template-columns: 25% 25%;
  grid-gap: 35%;
  margin-top: 40px;
  width: 100%;
  padding-left: 7.5%;
}
.grid-card {
  position: relative;
  height: 25vh;
  width: 100%;
  border: 1px solid rgb(253,248,255);
  margin: auto;
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  font-family: 'Avenir';
}
.grid-card p {
  background: transparent;
  color: white;
  font-family: 'Avenir';
  font-size: 1em;
  font-weight: normal;
  width: 80%;
  margin: 0;
  padding: 0;
}
.upper-div {
  position: absolute;
  top: 10%;
  left: 46%;
  transform: translateX(-46%);
  height: 35%;
  width: 20%;
  border: 1px solid rgb(253,248,255);
  margin: auto;
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  font-family: 'Avenir';
}
.upper-div p {
  text-align: center;
}
.lower-div {
  position: absolute;
  top: 50%;
  left: 46%;
  transform: translateX(-46%);
  height: 35%;
  width: 20%;
  border: 1px solid rgb(253,248,255);
  margin: auto;
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  font-family: 'Avenir';
}
.lower-div p {
  text-align: center;
}
@media screen and (min-width: 1000px) and (max-width: 1200px) {
  .card-container {
    min-height: 80vh;
  }
  .grid-card {
    height: 20vh;
  }
  .upper-div {
    height: 30%;
  }
  .lower-div {
    height: 30%;
  }
}
.data-grid p {
  background: transparent;
  color: white;
  font-family: 'Avenir';
  font-size: 1em;
  font-weight: normal;
  width: 80%;
  margin: 0;
  padding: 0;
}
.bold-text {
  font-size: 1.5em !important;
  font-weight: 900 !important;
  display: inline;
}


.login-page {
  height: 100vh;
  width: 100vw;
  background: rgba(20, 181, 163, 1);
}
.login-container {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.login-avatar {
  border-radius: 50%;
  height: 30vh;
  width: 30vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.login-page button {
  width: auto;
  font-size: 1.5em;
  font-family: Avenir;
  color: white;
  background: rgba(242, 114, 8, 1);
  border: none;
  border-radius: 50px;
  box-shadow:  0 2px 10px -3px rgba(0, 0, 0, 0.4);
  padding: 2px 15px 2px 15px;
}
.login-page button:hover {
  transform: translateY(1px);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.4);
}
.login-page button:active {
  transform: translateY(2px);
  box-shadow: none;
}
.login-page input {
  margin-bottom: 2vh;
  border: none;
  text-align: center;
  width: 25vh;
  border-radius: 50px;
  font-family: Avenir;
  font-size: 1em;
}


.admin-panel {
  height: auto;
  width: 100vw;
  padding-top: 5vw;
  position: relative;
  background: rgba(20, 181, 163, 1);
}
.back-button {
  fill: rgba(242, 114, 8, 1);
  height: 4.5vh;
  position: fixed;
  top: 5px;
  left: 5px;
}
.upload-pdf {
  border: 3px solid white;
  height: 50vh;
  width: 60vw;
  max-width: 300px;
  margin-top: 5vh;
  position: relative;
  margin: 0;
  margin-left: 50%;
  transform: translateX(-50%);
}
@media screen and (min-width: 900px) {
  .upload-pdf {
    margin-left: 0;
    transform: none;
  }
  
}
.upload-pdf-svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 100%;
  height: auto;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center,
}
.upload-pdf-svg p {
  color: white;
  font-family: Avenir;
  font-size: 2em;
  margin: 0;
  width: 100%;
  text-align: center;
  margin: 0;
}
.upload-pdf-button {
  height: calc(20vh - 5vw);
  display: flex;
  align-items: center;
  justify-content: center;
}
.pdf-image {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  height: auto;
  width: auto;
  z-index: 0;
}
.pdf-image img {
  height: 30vh;
  width: auto;
}
.pdf-image p {
  text-align: center;
  font-family: Avenir;
  font-size: 1.2em;
  color: white;
}


.job-holder {
  height: 100vh;
  width: 100vw;
  font-family: Avenir;
  background: rgba(20, 181, 163, 1);
}
.jobs-list {
  background: rgb(253,248,255);
  width: 90%;
  max-width: 800px;
  display: flex;
  flex-direction: row;
  margin: auto;
  border-radius: 8px;
  font-family: Avenir;
  margin-top: 2vh;
}
.jobs-list h2 {
  width: 100%;
  text-align: center;
  color: #F27208ff;
}
.jobs-list p {
  width: 100%;
}
.job-status {
  background: #F27208ff;
  width: 22%;
  border-radius: 0 8px 8px 0;
  color: white;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.job-status p {
  width: 100%;
  text-align: center;
}
.job-active {
  height: auto;
  width: calc(100% - 2vh);
}
.job-active p{
  padding: 0;
  margin: 0;
}
.job-active div {
  border: 2px solid white;
  background: rgba(200,200,200,0.4);
  height: 5vh;
  max-height: 40px;
  width: 5vh;
  max-width: 40px;
  margin-left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
}
.job-active-settings {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 2vh;
  color: white;
  font-family: Avenir;
  width: calc(100% - 2vh);
  margin-left: 1vh;
  margin-top: 2vh;
}
.job-active-settings-box {
  border: 2px solid white;
  background: rgba(200,200,200,0.4);
  height: 5vh !important;
  width: 5vh !important;
  display: flex;
  align-items: center;
  justify-content: center;

}


.job-infos {
  height: auto;
  width: 100vw;
  padding-top: 5vh;
  padding-left: 1vh;
  font-family: Avenir;
  font-size: 1em;
  color: white;
}
.job-infos input {
  height: 4vh;
  width: 60%;
}
.inline-p {
  display: inline;
}
@media screen and (min-width: 900px) {
  .admin-panel {
    display: grid !important;
    grid-template-columns: 50vw 50vw;
  }
  .job-infos {
    width: 50vw;
  }
}

.job-description {
  height: auto;
  min-height: 20vh;
  width: calc(100% - 2vh - 2px);
  border: none;
  border-radius: 8px;
  font-family: Avenir;
  font-size: 1em;
  scrollbar-width: none;
}
.task-qual {
  border: none;
  border-radius: 8px;
  width: calc(100% - 8vh);
  text-align: center;
  font-family: Avenir;
  font-size: 1em;
}


.news-page {
  height: 100vh;
  width: 100vw;
  background: rgb(253,248,255);
}
.news-title {
  position: absolute;
  height: 12vh;
  width: 60%;
  top: calc(10% + 2px);
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(20, 181, 163, 0.8);
  color: white;
  font-family: Avenir;
  font-size: 3em;
}
.jobs-images {
  top: 0;
  left: 0;
  height: 45vh;
  width: 100vw;
  position: relative;
}
.jobs-images img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
}
@media screen and (min-width: 900px) {
  .jobs-images {
    height: 65vh;
  }
  .jobs-images img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: 50% 25%;
  }
}
.news-content {
  padding-top: 5vh;
  width: 100%;
  max-width: 800px;
  margin: auto;
  height: auto;
  min-height: 30vh;
}
.news-content p {
  font-family: Avenir;
  font-size: 1em;
  text-align: justify;
  width: 95%;
  margin: auto;
  margin-top: 10px;
  margin-bottom: 10px;
}
.jobs-title {
  color: rgba(20, 181, 163, 1);

  font-family: Avenir;
  font-size: 2em;
  font-weight: bold;
  margin: 0;
  margin-left: 2.5%;
  margin-bottom: 2vh;
}
.frontend-jobs {
  width: 95%;
  margin: auto;
  height: 5vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  position: relative;
}
.frontend-jobs h2 {
  font-size: 1.3em;
  margin: 0;
  padding: 0;
  color: rgba(242, 114, 8, 1);
  font-family: Avenir;
  width: auto;
}
.frontend-jobs p {
  margin: 0;
  margin-left: 10px;
  padding: 0;
  font-size: 1.2em;
  color: rgba(242, 114, 8, 1);
  font-family: Avenir;
  width: auto;
}
.frontend-jobs-container {
  position: relative;
  height: auto;
}
.jobs-style-logo {
  position: absolute;
  bottom: calc(-5vh - 25px);
  left: 50%;
  transform: translateX(-50%);
  height: 50px;
}
.job-header {
  display: flex;
  flex-direction: row;
  gap: 2vh;
  width: 95%;
  font-family: Avenir;
}
.job-header div p {
  text-align: center;
  display: inline;
}
.job-header div h2 {
  text-align: center;
  display: inline;
  font-size: 1.1em;
}
.job-presentation {
  font-family: Avenir;
  font-size: 1em;
  width: 95%;
  margin: auto;
}
.job-presentation div {
  margin: 0;
}
.job-presentation p {
  margin: 0;
  padding: 0;
  width: 100%;
}
.job-presentation-task {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 2vh;
}
.job-presentation-task div {
  width: 10px;
  height: 10px;
  background: rgba(20, 181, 163, 1);
}
.download-pdf {
  padding: 5px 10px 5px 10px;
  width: auto;
  margin-top: 2vh;
  margin-bottom: 2vh;
  margin-left: 50%;
  transform: translateX(-50%);
  border: none;
  border-radius: 25px;
  background: #F27208ff;
  font-size: 1.2em;
  font-family: 'Avenir';
  box-shadow:  0 2px 10px -3px rgba(0, 0, 0, 0.4);
}
.download-pdf:active {
  transform: translateY(1px) translateX(-50%);
  box-shadow:  0 1px 2px rgba(0, 0, 0, 0.4);
}
.download-pdf:hover {
  transform: translateY(1px) translateX(-50%);
  box-shadow:  0 1px 2px rgba(0, 0, 0, 0.4);
}
.job-contact {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 3em;
}
.job-contact p {
  margin: 0;
  padding: 0;
  width: 50%;
  display: inline;
  width: max-content;
  margin: 0;
  padding: 5px;
  font-size: 0.9em;
  font-family: Avenir;
  color: rgb(20, 181, 163);
  display: flex;
  flex-direction: row;
}
.job-info ul {
  padding-left: 0;
  list-style-type: none !important;
}
.job-info ul li {
  position: relative;
  margin-bottom: 2vh;
  padding-left: calc(10px + 2vh);
}
.job-info ul li::before {
  content: '';
  position: absolute;
  left: 0;
  top: 5px;
  height: 10px;
  width: 10px;
  background: rgba(20, 181, 163, 1);
}
.text-editor {
  width: calc(100% - 2vh);
  height: auto;
  color: black;
}
.text-editor button {
  padding: 0;
  border-radius: 0;
}
.create-button {
  margin-top: 5vh;
  margin-left: 50%;
  transform: translateX(-50%);
}
.create-button:hover {
  transform: translateX(-50%) !important;
}